import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import MainPage from "../src/component/MainPage";
import VideoPage from "../src/component/VideosPage";
import FilesPage from "../src/component/FilesPage";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  fab,
  faTelegramPlane,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCheckSquare,
  faCoffee,
  faHome,
  faTerminal,
  faCode,
  faHeart,
  faFileArchive,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  fab,
  faCheckSquare,
  faCoffee,
  faHome,
  faTerminal,
  faCode,
  faHeart,
  faTelegramPlane,
  faFileArchive,
  faYoutube
);

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={MainPage} />
        <Route path="/videos" component={VideoPage} />
        <Route path="/files" component={FilesPage} />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
