import React, { useEffect } from "react";
import "../../index.css";
import Header from "../Header";
import Footer from "../Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FilesPage = () => {
  useEffect(() => {
    document.title = "Lethaquell | Files";
  }, []);

  return (
    <div className="wrapper" style={{ margin: 0, overflow: "hidden" }}>
      <video autoPlay muted loop className="back-loop">
        <source
          src="https://cdn.lethaquell.xyz/media/back-loop.mp4"
          type="video/mp4"
        />
      </video>

      <div className="page">
        <div className="container">
          <Header />

          <div className="content">
            <p>
              Web archive of important stuff{" "}
              <FontAwesomeIcon
                className="fa-file-archive"
                icon="file-archive"
              />
            </p>
            <a
              className="link-1"
              href="https://cdn.lethaquell.xyz/content/mods.zip"
            >
              wot modpack.
            </a>
            <p className="description-1">- For WoT version 1.8.0</p>
            <p className="description-2">
              Upload date: 22.04.2020 | Size: 17,600 KB
            </p>
          </div>

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default FilesPage;
