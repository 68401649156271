import React, { useEffect } from "react";
import "../../index.css";
import Header from "../Header";
import Footer from "../Footer";
import Link from "react-router-dom/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MainPage = () => {
  useEffect(() => {
    document.title = "Lethaquell | Hub";
  }, []);

  return (
    <div className="wrapper" style={{ margin: 0, overflow: "hidden" }}>
      <video autoPlay muted loop className="back-loop">
        <source
          src="https://cdn.lethaquell.xyz/media/back-loop.mp4"
          type="video/mp4"
        />
      </video>

      <div className="page">
        <div className="container">
          <Header />

          <div className="content">
            <p>Site is under construction</p>
            <p>
              <FontAwesomeIcon className="fa-terminal" icon="terminal" />
            </p>
            <Link className="link-1" to="files">
              files.
            </Link>
            <p className="description-1">- sharepoint (GoogleDrive? XD)</p>
            <Link className="link-1" to="videos">
              videos.
            </Link>
            <p className="description-1">- le collection of random trash</p>
            <a className="link-1" href="ts3server://94.103.92.135">
              teamspeak.
            </a>
            <p className="description-1">- in case if discord goes bye bye</p>
            <a className="link-1" href="https://lethaquell.tumblr.com/">
              tumblr.
            </a>
            <p className="description-1">
              - hey birb, wanna sum pics? here u go
            </p>
          </div>

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default MainPage;
