import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  return (
    <div className="footer">
      <FontAwesomeIcon className="fa-code" icon="code" /> with{" "}
      <FontAwesomeIcon className="fa-heart" icon="heart" /> by{" "}
      <a className="link-1" href="https://www.t.me/Lethaquell">
        Lethaquell
      </a>{" "}
      <FontAwesomeIcon
        className="fa-telegram-plane"
        icon={["fab", "telegram-plane"]}
      />
    </div>
  );
};

export default Footer;
