import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Header = () => {
  return (
    <div className="header">
      <FontAwesomeIcon className="fa-home" icon="home" />{" "}
      <Link className="link-1" to="/">
        Back to Hub
      </Link>{" "}
    </div>
  );
};

export default Header;
