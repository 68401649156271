import React, { useEffect } from "react";
import "../../index.css";
import Header from "../Header";
import Footer from "../Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VideosPage = () => {
  useEffect(() => {
    document.title = "Lethaquell | Videos";
  }, []);

  return (
    <div className="wrapper" style={{ margin: 0, overflow: "hidden" }}>
      <video autoPlay muted loop className="back-loop">
        <source
          src="https://cdn.lethaquell.xyz/media/back-loop.mp4"
          type="video/mp4"
        />
      </video>

      <div className="page">
        <div className="container">
          <Header />

          <div className="content">
            <p>
              Am I on P***hub already!?{" "}
              <FontAwesomeIcon
                className="fa-youtube"
                icon={["fab", "youtube"]}
              />
            </p>
            <a
              className="link-1"
              href="https://cdn.lethaquell.xyz/content/wot.mp4"
            >
              Hanz Zimmer - No Time for Caution (From "Interstellar")
            </a>
            <p className="description-1">
              - Some kind of bizarre (earrape) adventures of two WoT absolute
              chads
            </p>
            <p className="description-2">
              Upload date: 15.04.2020 | Length: 2 mins 57 secs
            </p>
            <br />
            <a
              className="link-1"
              href="https://cdn.lethaquell.xyz/content/mc1.mp4"
            >
              Minecraft Let's Play S1E1
            </a>
            <p className="description-1">
              - Chill and nice MC Let's Play! My Patreon link in description!
            </p>
            <p className="description-2">
              Upload date: 15.04.2020 | Length: 1 mins 26 secs
            </p>
            <br />
            <a
              className="link-1"
              href="https://cdn.lethaquell.xyz/content/mc2.mp4"
            >
              Minecraft Let's Play S1E2
            </a>
            <p className="description-1">
              - Next part of my Let's Play! You can also support my channel with
              <a className="link-1" href="https://paypal.me/Lethaquell">
                this PayPal link!
              </a>
            </p>
            <p className="description-2">
              Upload date: 15.04.2020 | Length: 1 mins 42 secs
            </p>
          </div>

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default VideosPage;
